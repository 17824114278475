
import { axiosInstance as api } from '../helpers/request'
import { ISubscriptionCards } from '../types/models';

class CardServices {
    getCards() {
        return api.get<ISubscriptionCards[]>('/card')
    }
    markAsPrimary(id: string) {
        return api.post<ISubscriptionCards>(`/card/${id}/primary`)
    }
    deleteCard(id: string) {
        return api.delete<ISubscriptionCards>(`/card/${id}`)
    }
    initAddCard() {
        return api.post('/card/init')
    }
}

const CardServicesInstance = new CardServices();

export default CardServicesInstance;