import { axiosInstance as api } from "../helpers/request";
import { IUser, InvoicesDataProps } from "../types/models";
import { ILoginResponse, IRegisterResponse } from "../types/response";
class AuthService {
  login(data: { email: string; password: string }) {
    return api.post<ILoginResponse>("/auth/login", data);
  }

  signup(data: {
    email: string;
    password: string;
    country: string;
    refKey: string;
  }) {
    return api.post<IRegisterResponse>("auth/register", data);
  }

  requestResetPassword(data: { email: string }) {
    return api.post("/auth/reset-password/request", { ...data, appVersion: 3 });
  }

  submitResetPassword(data: { token: string; password: string }) {
    return api.post("/auth/reset-password/submit", data);
  }

  getMe() {
    return api.get<IUser>("/auth/me");
  }

  submitVerifyAccount(data: { code: string }) {
    return api.post("/auth/verify", data);
  }

  resendVerifyAccount() {
    return api.post("/auth/verify/resend");
  }

  updateUserData(data: InvoicesDataProps) {
    return api.post("/auth/onboard", data);
  }
}

const authServiceInstance = new AuthService();

export default authServiceInstance;
