import React, { useEffect, useMemo, useState } from "react";
import {
  Avatar,
  Button,
  Grid,
  Menu,
  Layout,
  Dropdown,
  MenuProps,
  Typography,
  Badge,
  MenuItemProps,
  Flex,
  Breadcrumb,
  Tooltip,
  Row,
  Col,
} from "antd";
import "./dashboardLayout.scss";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { DarkModeSwitch } from "react-toggle-dark-mode";
import LogRocket from "logrocket";
import setupLogrocketReact from "logrocket-react";
import { ROUTES_MAP } from "../../routes/routesMap";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import {
  setSidebarCollapsed,
  setThemeMode,
} from "../../redux/Slices/LayoutSlice";
import { getMe, logoutUser } from "../../redux/Slices/AuthSlice";
import VerifyModal from "../../Components/Auth/VerifyModal/VerifyModal";
import { UploadProgress } from "../../Components/UploadProgress/UploadProgress";
import { updateUserCrispData } from "../../helpers/helpers";
import { IUser } from "../../types/models";
import { IconBase } from "../../assets/Icons/IconBase";
import { SidebarLiveIcon } from "../../assets/Icons/SidebarLive";
import { SidebarAffiliateIcon } from "../../assets/Icons/SidebarAffiliate";
import { SidebarKeyIcon } from "../../assets/Icons/SidebarKey";
import { SidebarBillingIcon } from "../../assets/Icons/SidebarBilling";
import { SidebarLogoutIcon } from "../../assets/Icons/SidebarLogout";
import { SidebarFAQIcon } from "../../assets/Icons/SidebarFAQ";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

const { Header, Sider, Content } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

interface IMenuProps extends MenuItemProps {
  // key: string;
}

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const MENU = (user?: IUser) => [
  {
    title: "Live Streams",
    key: ROUTES_MAP.dashboard.liveStreams,
    icon: (
      <IconBase>
        <SidebarLiveIcon />
      </IconBase>
    ),
  },
  {
    title: (
      <span>
        Affiliate
        {user && !user?.trialAvailable && (
          <Badge
            count="Popular"
            style={{ marginLeft: 10, color: "white" }}
          ></Badge>
        )}
      </span>
    ),
    icon: (
      <IconBase>
        <SidebarAffiliateIcon />
      </IconBase>
    ),
    key: ROUTES_MAP.dashboard.affiliate,
  },
  {
    title: "Stream Keys",
    key: ROUTES_MAP.dashboard.streamKeys,
    icon: (
      <IconBase>
        <SidebarKeyIcon />
      </IconBase>
    ),
  },
  {
    title: "FAQs",
    icon: (
      <IconBase>
        <SidebarFAQIcon />
      </IconBase>
    ),
    key: ROUTES_MAP.dashboard.faqs,
  },
  {
    title: <Menu.Divider />,
    key: "divider-2",
  },
  // {
  //   title: "Profile",
  //   key: ROUTES_MAP.dashboard.settings.profile,
  //   icon: (
  //     <IconBase>
  //       <SidebarProfileIcon />
  //     </IconBase>
  //   ),
  // },
  {
    title: "Billing",
    key: ROUTES_MAP.dashboard.settings.subscriptions,
    icon: (
      <IconBase>
        <SidebarBillingIcon />
      </IconBase>
    ),
  },
  // {
  //   title: "Feedback",
  //   key: "https://streamingbots.feedbq.io/",
  //   render: () => (
  //     <a
  //       data-feedbq-portal
  //       target="_blank"
  //       href="https://streamingbots.feedbq.io/"
  //     >
  //       Feedback
  //     </a>
  //   ),
  //   icon: (
  //     <IconBase>
  //       <SidebarBillingIcon />
  //     </IconBase>
  //   ),
  // },
  {
    title: "Logout",
    key: ROUTES_MAP.dashboard.logout,
    icon: (
      <IconBase style={{ transform: "rotate(180deg)" }}>
        <SidebarLogoutIcon />
      </IconBase>
    ),
  },
];

const HeaderDropdown = () => {
  const { sidebarCollapsed, themeMode, header } = useAppSelector(
    (state) => state.layout
  );
  const { user } = useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const toggleCollapsed = () => {
    dispatch(setSidebarCollapsed(!sidebarCollapsed));
  };

  const onLogoutUser = () => {
    dispatch(logoutUser());
  };

  const toggleTheme = () => {
    dispatch(setThemeMode(themeMode === "light" ? "dark" : "light"));
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <Typography.Paragraph>
          <strong>
            {user?.firstName} {user?.lastName}
          </strong>
          <br />
          {user?.email}
        </Typography.Paragraph>
      ),
    },
    // {
    //   key: "2",
    //   label: <Link to={ROUTES_MAP.dashboard.settings.profile}>Profile</Link>,
    // },
    {
      key: "3",
      label: (
        <Link to={ROUTES_MAP.dashboard.settings.subscriptions}>Billing</Link>
      ),
    },
    // {
    //   key: "5",
    //   label: (
    //     <a href="https://streamingbots.feedbq.io/" target="_blank">
    //       Feedback
    //     </a>
    //   ),
    // },
    {
      key: "4",
      label: (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button onClick={onLogoutUser}>Logout</Button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (user) {
      updateUserCrispData({
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
      });
      // window.FeedBQ.identifyUser({
      //   uid: user.id,
      //   name: `${user.firstName} ${user.lastName}`,
      //   email: user.email,
      // });
    }
  }, [user]);
  useEffect(() => {
    if (process.env.NODE_ENV === "production" && user?.email && user?.id) {
      LogRocket.init("nlbucs/streaming-bots", {
        network: {
          requestSanitizer: (request) => {
            if (request.url.includes("streaming-platform")) {
              delete request.body;
            }
            return request;
          },
          responseSanitizer: (response) => {
            if (response.status! < 400) {
              delete response.body;
            }
            return response;
          },
        },
        dom: {
          inputSanitizer: true,
        },
      });

      setupLogrocketReact(LogRocket);

      const id = `${user.email.split("@")[0]}-${user.id}`;
      LogRocket.identify(id);
    }
  }, [user?.email, user?.id]);

  return (
    <Header className={"header"}>
      <div>
        {header.navigations.length > 0 ? (
          <div>
            <Breadcrumb
              separator=">"
              items={header.navigations.map((nav) => ({
                title: nav.title,
                onClick: () => nav.url && navigate(nav.url),
                href: nav.url,
              }))}
              itemRender={(route) => {
                if (route.href) {
                  return <Link to={route.href}>{route.title}</Link>;
                }
                const stringTitle = route.title as string;
                if (stringTitle.length > 30) {
                  return (
                    <Tooltip title={stringTitle}>
                      <Typography.Text ellipsis>
                        {stringTitle.substring(0, 27)}...
                      </Typography.Text>
                    </Tooltip>
                  );
                }
                return (
                  <Typography.Text ellipsis>{route.title}</Typography.Text>
                );
              }}
            />
          </div>
        ) : null}
        <Typography.Title level={1} style={{ margin: 0 }}>
          {header.title.length > 30 ? (
            <Tooltip title={header.title}>
              {header.title.substring(0, 27) + "..."}
            </Tooltip>
          ) : (
            header.title
          )}
        </Typography.Title>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        {/* <div
          style={{ marginRight: 10, display: "flex", justifyContent: "center" }}
        >
          <DarkModeSwitch
            onChange={toggleTheme}
            checked={themeMode === "dark"}
          />
        </div> */}
        <Dropdown menu={{ items }} placement="bottomLeft" trigger={["click"]}>
          <Avatar
            size="large"
            style={{ backgroundColor: "#f56a00", cursor: "pointer" }}
          >
            {user?.firstName?.charAt(0).toUpperCase()}
            {user?.lastName?.charAt(0).toUpperCase()}
          </Avatar>
        </Dropdown>
        <VerifyModal />
      </div>
    </Header>
  );
};

const SideMenu = () => {
  const breakpoint = Grid.useBreakpoint();
  const location = useLocation();
  const { sidebarCollapsed, themeMode } = useAppSelector(
    (state) => state.layout
  );
  const { user } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const selectedKeys = useMemo(() => {
    const currentItem = MENU(user)
      .flat(3)
      .find((item) =>
        location.pathname.startsWith(item?.key?.toString() || "")
      );
    return currentItem ? [currentItem.key!] : [];
  }, [location.pathname]);

  const onMenuItemClick = () => {
    if (!breakpoint.sm) {
      dispatch(setSidebarCollapsed(false));
    }
  };

  useEffect(() => {
    if (!breakpoint.sm) {
      dispatch(setSidebarCollapsed(true));
    } else {
      dispatch(setSidebarCollapsed(false));
    }
  }, [breakpoint.sm]);

  return (
    <Sider
      className="sidebar"
      // collapsed={
      //   location.pathname.includes("/dashboard/videos/") ? true : collapsed
      // }
      collapsed={sidebarCollapsed}
      width={250}
    >
      <Button
        size="small"
        style={{ position: "absolute", right: -40, top: 50 }}
        onClick={() => dispatch(setSidebarCollapsed(!sidebarCollapsed))}
      >
        {sidebarCollapsed ? <RightOutlined /> : <LeftOutlined />}
      </Button>
      <Row>
        <Col xs={0} md={24}>
          <Flex
            justify="flex-start"
            align="center"
            style={{ width: "100%", paddingLeft: sidebarCollapsed ? 10 : 24 }}
          >
            <div
              style={{
                margin: "20px 0",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Link to={ROUTES_MAP.dashboard.liveStreams}>
                {/* <Image width={60} src={`/logo_${themeMode}.png`} preview={false} /> */}
                <img
                  src="/images/Logo-Black.svg"
                  style={{ maxWidth: "100%" }}
                  alt="logo"
                />
              </Link>
              {!sidebarCollapsed && (
                <Typography.Title
                  level={4}
                  style={{
                    fontWeight: "bold",
                    fontSize: 20,
                    margin: 0,
                    padding: 0,
                  }}
                >
                  Streaming Bots
                </Typography.Title>
              )}
            </div>
          </Flex>
        </Col>
        <Col xs={24} md={0}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Link to={ROUTES_MAP.dashboard.liveStreams}>
              {/* <Image width={60} src={`/logo_${themeMode}.png`} preview={false} /> */}
              <img
                src="/images/Logo-Black.svg"
                style={{ maxWidth: "100%" }}
                alt="logo"
              />
            </Link>
          </div>
        </Col>
      </Row>
      <Menu mode="inline" selectedKeys={selectedKeys}>
        {MENU(user).map((item) =>
          item.key.includes("divider") ? (
            <Menu.Divider key={item.key} />
          ) : (
            <Menu.Item
              icon={item.icon}
              key={item.key}
              onClick={onMenuItemClick}
              style={{
                paddingLeft: sidebarCollapsed ? 8 : 12,
                paddingRight: sidebarCollapsed ? 8 : 12,
                paddingTop: sidebarCollapsed ? 4 : 0,
              }}
            >
              <Link to={item.key}>{item.title}</Link>
              {/* {item.render ? (
                item.render()
              ) : (
                <Link to={item.key}>{item.title}</Link>
              )} */}
            </Menu.Item>
          )
        )}
      </Menu>
      {user?.hasOldExperience && !sidebarCollapsed ? (
        <a href="https://app.streamingbots.com/dashboard" target="_blank">
          <Button type="link" style={{ width: "100%", marginTop: 10 }}>
            Go to old dashboard
          </Button>
        </a>
      ) : null}
    </Sider>
  );
};

const DashboardLayout: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getMe());

    const interval = setInterval(() => {
      dispatch(getMe());
    }, 5 * 60 * 1000);

    return () => clearInterval(interval);
  }, [dispatch]);

  return (
    <Layout className={"dashboardLayout"}>
      <UploadProgress />
      <Layout hasSider>
        <SideMenu />
        <Content className="content">
          <HeaderDropdown />
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default DashboardLayout;
