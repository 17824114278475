import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IStreamingPlatform } from '../../types/models';
import affiliateServiceInstance from '../../api/AffiliateService';
import { IReferral } from '../../types/interfaces';
import { message } from 'antd';

const initialState: IReferral = {
    key: null,
    invited: 0,
    pending: 0,
    paid: 0,
    available: 0,
    referralInfoLoading: false,
    referralInfoError: null,

};

export const AffiliateSlice = createSlice({
    name: 'affiliate',
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            state.referralInfoLoading = action.payload
        },
        setReferral(state, action: PayloadAction<IReferral>) {
            state.key = action.payload.key
            state.available = action.payload.available
            state.paid = action.payload.paid
            state.pending = action.payload.pending
            state.invited = action.payload.invited
        },
    },
});

export const { setLoading, setReferral } = AffiliateSlice.actions;

export const updateReferralKey = createAsyncThunk(
    "affiliate/updateReferralKey",
    async (data: { key: string }, { dispatch }) => {
        dispatch(setLoading(true));
        try {
            await affiliateServiceInstance.updateReferralKey(data);
        } catch (err: any) {
            message.error(err.message)
        }
        dispatch(setLoading(false));
    }
);

export const getReferralInfo = createAsyncThunk(
    'affiliate/getReferralInfo',
    async (_, { dispatch }) => {
        dispatch(setLoading(true));
        try {
            const res = await affiliateServiceInstance.getReferralInfo();
            dispatch(setReferral(res.data))
        } catch (err: any) {
            message.error(err.message)
        }
        dispatch(setLoading(false));
    }
)