import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// const initialThemeColor = localStorage.getItem('THEME_COLOR') as 'light' | 'dark' || 'dark'
const initialThemeColor = 'dark';

interface InitialState {
    themeMode: 'light' | 'dark',
    sidebarCollapsed: boolean,
    header: {
        title: string,
        navigations: { title: string, url?: string }[],
    },
    themeColors?: {
        colorPrimary: string,
        textPrimary: string,
        textSecondary: string,
        bgMain: string,
        bgSecondary: string,
        bgGradientMain: string,
    }
}

const initialState: InitialState = {
    themeMode: initialThemeColor,
    sidebarCollapsed: false,
    header: {
        title: "Live Streams",
        navigations: [],
    }
}

export const LayoutSlice = createSlice({
    name: "layout",
    initialState,
    reducers: {
        setThemeMode(state, action: PayloadAction<'light' | 'dark'>) {
            state.themeMode = action.payload;
            localStorage.setItem("THEME_COLOR", action.payload)
        },
        setSidebarCollapsed(state, action: PayloadAction<boolean>) {
            state.sidebarCollapsed = action.payload
        },
        setHeader(state, action: PayloadAction<InitialState['header']>) {
            state.header = action.payload
        },
        setThemeColors(state, action: PayloadAction<InitialState['themeColors']>) {
            state.themeColors = action.payload
        }
    },
});


export const {
    setThemeMode,
    setSidebarCollapsed,
    setHeader,
    setThemeColors,
} = LayoutSlice.actions
