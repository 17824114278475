import { axiosInstance as api } from '../helpers/request'
import { ICreateLiveStreamRequest, ICreatePlaylistRequest, ICreateVideoRequest, IUpdateLiveStreamRequest, IUpdatePlaylistRequest, IUpdateVideoRequest } from '../types/interfaces';
import { ILiveStream, IPlaylist, IVideo } from '../types/models';

class LiveStreamService {
    getActiveLiveStreams() {
        return api.get<ILiveStream[]>('/live-stream/active')
    }

    getLiveStreamById(id: string) {
        return api.get<ILiveStream>(`/live-stream/${id}`)
    }

    createVideo(data: ICreateVideoRequest) {
        return api.post<IVideo>('/video', data)
    }

    updateVideo(data: IUpdateVideoRequest) {
        return api.put<IVideo>(`/video/${data.id}`, data)
    }

    batchCreateVideo(videos: ICreateVideoRequest[]) {
        return api.post<IVideo[]>('/video/batch', { videos })
    }

    batchUpdateVideos(videos: IUpdateVideoRequest[]) {
        return api.patch<IVideo[]>('/video/batch', { videos })
    }

    createPlaylist(data: ICreatePlaylistRequest) {
        return api.post<IPlaylist>('/playlist', data)
    }

    updatePlaylist(data: IUpdatePlaylistRequest) {
        return api.put<IPlaylist>(`/playlist/${data.id}`, data)
    }

    batchCreatePlaylist(playlists: ICreatePlaylistRequest[]) {
        return api.post<IPlaylist[]>('/playlist/batch', { playlists })
    }

    batchUpdatePlaylists(playlists: IUpdatePlaylistRequest[]) {
        return api.patch<IPlaylist[]>(`/playlist/batch`, { playlists })
    }


    updatePromoted(data: { id: string, promoted: boolean, promotedUrl: string }) {
        return api.put<ILiveStream>(`/live-stream/${data.id}/promote`, data)
    }

    updateVideoKey(data: { id: string, platformId: string, videoKey: string }) {
        return api.put<IVideo>(`/live-stream/${data.id}/videoKey`, data)
    }

    createLiveStream(data: ICreateLiveStreamRequest) {
        return api.post<ILiveStream>('/live-stream', data)
    }

    updateLiveStream(data: IUpdateLiveStreamRequest) {
        return api.put<ILiveStream>(`/live-stream/${data.id}`, data)
    }

    restartLiveStream(id: string) {
        return api.put<ILiveStream>(`/live-stream/${id}/restart`)
    }

    endLiveStream(id: string) {
        return api.put<ILiveStream>(`/live-stream/${id}/end`)
    }
}

const LiveStreamServiceInstance = new LiveStreamService();

export default LiveStreamServiceInstance;