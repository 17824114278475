import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IStreamSelectedItem } from "../../types/interfaces";
import { IAudio, IVisualMedia } from "../../types/models";

interface Show {
  name: string;
  startAt: string;
  endsAt?: string;
}

interface initialState {
  shows: Show[];
  showItems: IStreamSelectedItem[];
}

const initialState: initialState = {
  shows: [],
  showItems: [],
};

export const ShowsSlice = createSlice({
  name: "shows",
  initialState,
  reducers: {
    createShow(state, action: PayloadAction<Show>) {
      state.shows = [...state.shows, action.payload];
    },
    setShowItem(state, action: PayloadAction<IStreamSelectedItem[]>) {
      state.showItems = action.payload;
    },
    removeShowsFromList(state, action: PayloadAction<IStreamSelectedItem>) {
      const newItems = state.showItems.filter(
        (audio) => audio.id !== action.payload.id
      );
      newItems.sort((a, b) => a.order - b.order);
      state.showItems = newItems.map((item, index) => ({
        ...item,
        order: index + 1,
      }));
    },
  },
});

export const { createShow, setShowItem, removeShowsFromList } =
  ShowsSlice.actions;
