import CustomPlatform from "../assets/Icons/CustomPlatform";
import KickIcon from "../assets/Icons/KickIcon";
import TwitchIcon from "../assets/Icons/Twitch";
import TwitchBw from "../assets/Icons/TwitchBw";
import YoutubeBw from "../assets/Icons/YoutubeBw";
import YoutubeIcon from "../assets/Icons/YoutubeIcon";

export const MAX_FILE_SIZE_GB = 5;
export const MAX_FILE_DURATION_MINUTES = 2 * 60;
export const COLUMN_1 = "column-1";
export const COLUMN_2 = "column-2";

export const IMAGE_PLACEHOLDER_URL = "/images/image_placeholder.jpeg";
export const GIF_PLACEHOLDER_URL = "/images/image_placeholder.jpeg";
export const VIDEO_PLACEHOLDER_URL = "/video_placeholder.mp4";

export const STREAM_TYPE_PLAYLIST = "playlist";
export const STREAM_TYPE_VIDEO = "video";

export const STREAM_RADIO_EXAMPLE_STREAM_ID = "XAQsEKhNtNA";

export const DATE_FORMAT_FRONTEND = "MM/dd/yyyy";
export const DATE_TIME_FORMAT_FRONTNED = `${DATE_FORMAT_FRONTEND} hh:mm a`;
export const REFERRAL_LINK = "https://my.streamingbots.com/join/";

export type PlatformIcon =
  | {
      colored: React.ComponentType<{ width?: string; height?: string }>;
      black: React.ComponentType<{}>;
    }
  | string;

export const DEFAULT_PLATFORMS: {
  id: number;
  name: string;
  label: string;
  value: string;
  icon: PlatformIcon;
  regex?: RegExp;
}[] = [
  {
    id: 2,
    name: "youtube",
    label: "Youtube",
    value: "youtube",
    icon: { colored: YoutubeIcon, black: YoutubeBw },
    regex:
      /^[a-zA-Z0-9]{4}\-[a-zA-Z0-9]{4}\-[a-zA-Z0-9]{4}\-[a-zA-Z0-9]{4}\-[a-zA-Z0-9]{4}$/i,
  },
  {
    id: 3,
    name: "twitch",
    label: "Twitch",
    value: "twitch",
    icon: { colored: TwitchIcon, black: TwitchBw },
    regex: /^live\_(.)+$/i,
  },
  {
    id: 4,
    name: "kick",
    label: "Kick",
    value: "kick",
    icon: { colored: KickIcon, black: KickIcon },
  },
  {
    id: 5,
    name: "custom",
    label: "Custom",
    value: "custom",
    icon: { colored: CustomPlatform, black: CustomPlatform },
  },
];

export const VIDEO_LOOP_EXAMPLE_STREAM_ID = "Q3aRvOo9A7I";

export const HOW_TO_DISABLE_ENABLE_AUTO_STOP_VIDEO_ID = "Kg3Wx6Oxvyg";
export const HOW_TO_CHANGE_STREAM_CONTENT_VIDEO_ID = "uz6zK44N9AQ";
export const FULL_TUTORIAL_VIDEO_ID = "-np8p8n-kN0";

export const STREAM_RADIO_EXAMPLE_STREAM =
  "https://www.youtube.com/watch?v=XAQsEKhNtNA&ab_channel=StreamingBots";

export const VIDEO_LOOP_EXAMPLE_STREAM =
  "https://www.youtube.com/watch?v=Q3aRvOo9A7I&ab_channel=StreamingBots";

export const TUTORIAL_VIDEO_ID = "-np8p8n-kN0";
export const MULTI_ITEM_TUTORIAL_VIDEO_ID = "V030e39xDvs";
export const SCHEDULE_STREAM_TUTORIAL_VIDEO_ID = "7uCCPHVZ09o";

export const MAX_FILE_SIZE_BYTES = 10 * 1024 * 1024 * 1024;
export const ENABLE_PIXELS = true;

export const REFERRAL_KEY = "REFERRAL_KEY";

export const MAX_LIVE_STREAM_ITEMS_NO = 10;
export const MAX_TOTAL_DURATION_SECONDS = 4 * 60 * 60;

export const MULTI_LIVE_STREAM_ITEM_WORKER_VERSION = 2;

export const SHOW_ITEM_PREFIX = "show_item_" as const;
export const JINGLE_ITEM_PREFIX = "jingle_item_" as const;
export const MAX_VIDEO_WIDTH = 1920;
export const MAX_VIDEO_HEIGHT = 1080;
export const VERTICAL_VIDEO_WIDTH = 360;
export const VERTICAL_VIDEO_HEIGHT = (VERTICAL_VIDEO_WIDTH * 16) / 9;
export const VIDEO_BITRATE_OPTIONS = [
  3000, 3500, 4000, 4500, 5000, 5500, 6000, 6500, 7000, 8000, 9000, 10000,
  12000, 20000,
];
export const DEFAULT_VIDEO_BITRATE = 7000;

export const AUDIO_BITRATE_OPTIONS = [128, 256, 320];
export const DEFAULT_AUDIO_BITRATE = 320;

export const FPS_OPTIONS = [25, 30];
export const DEFAULT_FPS = 30;
