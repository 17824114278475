import { axiosInstance as api } from '../helpers/request'
import { IStreamingPlatformKey } from '../types/interfaces';
import { IStreamingPlatform } from '../types/models';
import ApiUrlConstans from './ApiUrlConstants';

class StreamKeysService {
    addStreamPlatform(platform: IStreamingPlatformKey) {
        return api.post<IStreamingPlatform>('/streaming-platform', platform)
    }

    updateStreamPlatform(platform: IStreamingPlatformKey) {
        return api.put<IStreamingPlatform>(`/streaming-platform/${platform.id}`, platform)
    }

    getStreamPlatforms() {
        return api.get<IStreamingPlatform[]>('/streaming-platform')
    }

    deleteStreamPlatform(id: string) {
        return api.delete<IStreamingPlatform>(`/streaming-platform/${id}`)
    }
}

const StreamKeysServiceInstance = new StreamKeysService();

export default StreamKeysServiceInstance;