export const SidebarKeyIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.92898 4.92894C2.97636 6.88156 2.97636 10.0474 4.92898 12C6.23763 13.3087 8.09123 13.7403 9.7593 13.2948L14.364 17.8995C14.942 18.4775 15.2311 18.7666 15.5986 18.9188C15.9662 19.0711 16.3749 19.0711 17.1925 19.0711L19.0711 19.0711V16.0896C19.0711 15.9522 19.0711 15.8833 19.0623 15.8175C19.0393 15.6447 18.9715 15.4809 18.8655 15.3424C18.8252 15.2896 18.7765 15.241 18.6793 15.1437C18.6006 15.065 18.5613 15.0257 18.5189 14.9916C18.4076 14.9023 18.2787 14.8378 18.1405 14.8025C18.0878 14.789 18.0327 14.7811 17.9226 14.7654L15.8891 14.4749L15.7242 13.32C15.6307 12.6658 15.5839 12.3387 15.4389 12.0484C15.2937 11.7581 15.0601 11.5245 14.5928 11.0572L13.2948 9.75926C13.7403 8.0912 13.3087 6.23759 12 4.92894C10.0474 2.97632 6.88159 2.97634 4.92898 4.92894Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M8.46451 8.46448L8.47086 8.45813"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
