import { useEffect } from "react";
import { useAppDispatch } from "../../../redux/store";
import { logoutUser } from "../../../redux/Slices/AuthSlice";

export const LogoutPage = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(logoutUser());
  }, []);

  return null;
};
