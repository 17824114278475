import React from "react";

const TwitchBw = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="37"
      viewBox="0 0 32 37"
      fill="none"
    >
      <path
        d="M15.1114 7.32107H17.6854V15.0251H15.1114M22.1854 7.32107H24.7594V15.0251H22.1854M6.75937 0.24707L0.333374 6.67307V29.8211H8.03737V36.2471L14.4814 29.8211H19.6114L31.1854 18.2471V0.24707M28.6114 16.9691L23.4814 22.0991H18.3334L13.8334 26.5991V22.0991H8.03737V2.82107H28.6114V16.9691Z"
        fill="#6656ED"
      />
    </svg>
  );
};

export default TwitchBw;
