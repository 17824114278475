import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import authServiceInstance from "../../api/AuthApi";
import { notification } from "antd";
import { ROUTES_MAP } from "../../routes/routesMap";
import { InvoicesDataProps, IUser } from "../../types/models";
import { ILoginResponse } from "../../types/response";

interface InitialState {
  isLoading: boolean;
  user?: IUser;
  token: string;
  resetEmailSent: boolean;
  passwordUpdated: boolean;
  verifyUserLoading: boolean;
  resendLoading: boolean;
  updateLoading: boolean;
}

const storedUser = localStorage.getItem("user");
const storedToken = localStorage.getItem("token");
let initialUser = null;
try {
  initialUser = storedUser ? JSON.parse(storedUser) : null;
} catch (err) {
  console.log(err);
}

const initialState: InitialState = {
  user: initialUser,
  token: storedToken || "",
  isLoading: false,
  resetEmailSent: false,
  passwordUpdated: false,
  verifyUserLoading: false,
  resendLoading: false,
  updateLoading: false,
};

export const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setVerifyLoading(state, action: PayloadAction<boolean>) {
      state.verifyUserLoading = action.payload;
    },
    setResendLoading(state, action: PayloadAction<boolean>) {
      state.resendLoading = action.payload;
    },
    onLoginSuccess(state, action: PayloadAction<ILoginResponse>) {
      const { user, token } = action.payload;
      state.user = user;
      state.token = token;
      localStorage.setItem("token", token);
      localStorage.setItem("user", JSON.stringify(user));
      let lastLocationKey = `location-${user.id}`;
      const lastLocation = window.localStorage.getItem(lastLocationKey);
      if (lastLocation && !lastLocation.includes("as-user")) {
        window.location.href = lastLocation;
      } else {
        window.location.href = ROUTES_MAP.dashboard.liveStreams;
      }
    },
    onGetMeSuccess(state, action: PayloadAction<IUser>) {
      state.user = action.payload;
      localStorage.setItem("user", JSON.stringify(action.payload));
    },
    logoutUser(state) {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      state.token = "";
      state.user = undefined;
      window.location.href = ROUTES_MAP.auth.login;
    },
    setResetEmailSent(state, action: PayloadAction<boolean>) {
      state.resetEmailSent = action.payload;
    },
    setPasswordUpdated(state, action: PayloadAction<boolean>) {
      state.passwordUpdated = action.payload;
    },
    setUpdateUserLoading(state, action: PayloadAction<boolean>) {
      state.updateLoading = action.payload;
    },
    setToken(state, action: PayloadAction<string>) {
      state.token = action.payload;
      localStorage.setItem("token", action.payload);
    },
  },
});

export const {
  setLoading,
  onLoginSuccess,
  logoutUser,
  setResetEmailSent,
  setPasswordUpdated,
  onGetMeSuccess,
  setVerifyLoading,
  setResendLoading,
  setUpdateUserLoading,
  setToken,
} = AuthSlice.actions;

export const login = createAsyncThunk(
  "auth/login",
  async (
    { email, password }: { email: string; password: string },
    { rejectWithValue, dispatch }
  ) => {
    dispatch(setLoading(true));
    try {
      const response = await authServiceInstance.login({ email, password });
      const data = response.data;
      dispatch(onLoginSuccess(data));
    } catch (err: any) {
      notification.error({ message: err.message });
    }

    dispatch(setLoading(false));
  }
);

export const register = createAsyncThunk(
  "auth/register",
  async (
    data: { email: string; password: string; country: string; refKey: string },
    { dispatch }
  ) => {
    dispatch(setLoading(true));
    try {
      const response = await authServiceInstance.signup(data);
      dispatch(onLoginSuccess(response.data));
    } catch (err: any) {
      console.log({ err });
      notification.error({ message: err.message });
    }
    dispatch(setLoading(false));
  }
);

export const getMe = createAsyncThunk("auth/getMe", async (_, { dispatch }) => {
  dispatch(setLoading(true));
  try {
    const response = await authServiceInstance.getMe();
    dispatch(onGetMeSuccess(response.data));
  } catch (err: any) {
    console.log({ err });
    notification.error({ message: err.message });
  }
  dispatch(setLoading(false));
});

export const requestResetPassword = createAsyncThunk(
  "auth/requestResetPassword",
  async (data: { email: string }, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await authServiceInstance.requestResetPassword(data);
      dispatch(setResetEmailSent(true));
    } catch (err: any) {
      console.log({ err });
      notification.error({ message: err.message });
    }
    dispatch(setLoading(false));
  }
);

export const submitResetPassword = createAsyncThunk(
  "auth/submitResetPassword",
  async (data: { token: string; password: string }, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      await authServiceInstance.submitResetPassword(data);
      notification.success({ message: "Password updated succesfully" });
      dispatch(setPasswordUpdated(true));
    } catch (err: any) {
      console.log({ err });
      notification.error({ message: err.message });
    }
    dispatch(setLoading(false));
  }
);

export const verifyUser = createAsyncThunk(
  "auth/verifyUser",
  async (data: { code: string }, { dispatch }) => {
    dispatch(setVerifyLoading(true));
    try {
      await authServiceInstance.submitVerifyAccount(data);
      await dispatch(getMe());
      notification.success({ message: "Account verified successfully!" });
    } catch (err: any) {
      console.log({ err });
      notification.error({ message: err.message });
    }
    dispatch(setVerifyLoading(false));
  }
);

export const resendVerifyUser = createAsyncThunk(
  "auth/resendVerifyUser",
  async (_, { dispatch }) => {
    dispatch(setResendLoading(true));
    try {
      await authServiceInstance.resendVerifyAccount();
      notification.success({ message: "Email sent succesfully!" });
    } catch (err: any) {
      console.log({ err });
      notification.error({ message: err.message });
    }
    dispatch(setResendLoading(false));
  }
);

export const updateUserData = createAsyncThunk(
  "auth/updateUserData",
  async (data: InvoicesDataProps, { dispatch }) => {
    dispatch(setUpdateUserLoading(true));
    try {
      await authServiceInstance.updateUserData(data);
      await dispatch(getMe());
    } catch (err: any) {
      console.log({ err });
      notification.error({ message: err.message });
    }
    dispatch(setUpdateUserLoading(false));
  }
);

export const impersonateUser = createAsyncThunk(
  "auth/impersonateUser",
  async (_, { dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await authServiceInstance.getMe();
      window.localStorage.setItem("user", JSON.stringify(response.data));
      window.location.href = ROUTES_MAP.dashboard.liveStreams;
    } catch (err: any) {
      console.log({ err });
      notification.error({ message: err.message });
    }
    dispatch(setLoading(false));
  }
);