import React from "react";

const KickIcon = ({
  width = "100%",
  height = "auto",
}: {
  width?: string;
  height?: string;
}) => {
  return (
    <svg
      data-v-27722a4f=""
      data-v-d08e846c=""
      width={width}
      height={height}
      viewBox="0 0 80 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        data-v-27722a4f=""
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 0H8.57143V5.71429H11.4286V2.85714H14.2857V0H22.8571V8.57143H20V11.4286H17.1429V14.2857H20V17.1429H22.8571V25.7143H14.2857V22.8571H11.4286V20H8.57143V25.7143H0V0ZM57.1429 0H65.7143V5.71429H68.5714V2.85714H71.4286V0H80V8.57143H77.1429V11.4286H74.2857V14.2857H77.1429V17.1429H80V25.7143H71.4286V22.8571H68.5714V20H65.7143V25.7143H57.1429V0ZM25.7143 0H34.2857V25.7143H25.7143V0ZM45.7143 0H40V2.85714H37.1429V22.8571H40V25.7143H45.7143H54.2857V17.1429H45.7143V8.57143H54.2857V0H45.7143Z"
        fill="#6656ED"
      ></path>
    </svg>
  );
};

export default KickIcon;
