import React from "react";

interface IconBaseProps {
  children: React.ReactNode;
  style?: any;
  className?: string;
  onClick?: () => void;
}

export const IconBase = ({
  children,
  style,
  className,
  onClick = () => {},
}: IconBaseProps) => {
  const renderedStyle = {
    ...(style || {}),
    cursor: "pointer",
  };
  return (
    <span style={renderedStyle} className={className || ""} onClick={onClick}>
      {children}
    </span>
  );
};
