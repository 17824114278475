
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import FolderInstance from "../../api/FolderApi";
import { FolderTypeEnum, IFolder } from "../../types/models";

interface FolderState {
    loading: boolean;
    folders: IFolder[];
    folderById?: IFolder | null;
    addItemsToFolderLoading: boolean;
}

const initialState: FolderState = {
    folders: [],
    folderById: null,
    loading: false,
    addItemsToFolderLoading: false,
};

export const folderSlice = createSlice({
    name: "folder",
    initialState,
    reducers: {
        setFolders(state, action: PayloadAction<IFolder[]>) {
            state.folders = action.payload;
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        setFolderById(state, action: PayloadAction<IFolder | undefined | null>) {
            state.folderById = action.payload;
        },
        setAddItemsToFolderLoading(state, action: PayloadAction<boolean>) {
            state.addItemsToFolderLoading = action.payload;
        },
        onFolderNameChange(state, action: PayloadAction<{ id: string, name: string }>) {
            const index = state.folders.findIndex(folder => folder.id === action.payload.id);
            if (index !== -1) {
                state.folders[index].name = action.payload.name;
            }
            if (state.folderById?.id === action.payload.id) {
                state.folderById.name = action.payload.name;
            }
        },
        onFolderDelete(state, action: PayloadAction<string>) {
            const index = state.folders.findIndex(folder => folder.id === action.payload);
            if (index !== -1) {
                state.folders.splice(index, 1);
            }
        },
        onFolderCreate(state, action: PayloadAction<IFolder>) {
            state.folders.push(action.payload);
        }
    },
    extraReducers: {
    }
});

export const {
    setLoading,
    setAddItemsToFolderLoading,
    setFolderById,
    setFolders,
    onFolderNameChange,
    onFolderDelete,
    onFolderCreate
} = folderSlice.actions;

export const getFolders = createAsyncThunk(
    "folder/getFolders",
    async (type: FolderTypeEnum, { dispatch }) => {
        dispatch(setLoading(true));
        try {
            const response = await FolderInstance.getFolders(type)
            dispatch(setFolders(response.data));
        } catch (err: any) {
            console.log({ err })
        }
        dispatch(setLoading(false));
    }
)

export const createFolder = createAsyncThunk(
    "folder/createFolder",
    async (folder: Pick<IFolder, 'name' | 'type'>, { dispatch }) => {
        dispatch(setLoading(true));
        try {
            const response = await FolderInstance.createFolder(folder)
            dispatch(onFolderCreate(response.data))
        } catch (err: any) {
            console.log({ err })
        }
        dispatch(setLoading(false));
    }
)

export const updateFolder = createAsyncThunk(
    "folder/updateFolder",
    async (folder: Pick<IFolder, 'id' | 'name'>, { dispatch }) => {
        dispatch(setLoading(true));
        try {
            await FolderInstance.updateFolder(folder)
            dispatch(onFolderNameChange(folder))
        } catch (err: any) {
            console.log({ err })
        }
        dispatch(setLoading(false));
    }
);

export const getFolderById = createAsyncThunk(
    "folder/getFolderById",
    async (id: string, { dispatch }) => {
        dispatch(setLoading(true));
        try {
            const response = await FolderInstance.getFolderById(id)
            dispatch(setFolderById(response.data));
        } catch (err: any) {
            console.log({ err })
        }
        dispatch(setLoading(false));
    }
)

export const deleteFolder = createAsyncThunk(
    "folder/deleteFolder",
    async (id: string, { dispatch }) => {
        dispatch(setLoading(true));
        try {
            await FolderInstance.deleteFolder(id)
            dispatch(onFolderDelete(id))
        } catch (err: any) {
            console.log({ err })
        }
        dispatch(setLoading(false));
    }
)

export const addItemsToFolder = createAsyncThunk(
    "folder/addItemsToFolder",
    async (data: { folderId: string | null, itemIds: string[], type: FolderTypeEnum }, { dispatch }) => {
        dispatch(setLoading(true));
        try {
            await FolderInstance.addItemsToFolder(data)
        } catch (err: any) {
            console.log({ err })
        }
        dispatch(setLoading(false));
    }
)