import { FC, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { Button, Card, Col, Flex, Row, Spin, Typography } from "antd";
import { CloseOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import { clearPendingUploads } from "../../redux/Slices/MediaSlice";
import UploadCard from "./UploadCard/UploadCard";
import { FixedSizeList as List } from "react-window";

export const UploadProgress: FC = () => {
  const [minimized, setMinimized] = useState(false);

  const { pendingUploads } = useAppSelector((state) => state.media);

  const dispatch = useAppDispatch();

  const finishedCount = useMemo(() => {
    return pendingUploads.filter((upload) => upload.progress === 100).length;
  }, [pendingUploads]);

  if (pendingUploads.length === 0) return null;

  return (
    <div
      style={{
        position: "absolute",
        bottom: 0,
        right: 0,
        zIndex: 999,
        width: 300,
      }}
    >
      <Card
        bodyStyle={{
          maxHeight: 500,
          overflowY: "auto",
          display: minimized ? "none" : "block",
        }}
        title={
          <Flex justify="space-between">
            <Typography.Text>
              {finishedCount !== pendingUploads.length && (
                <Spin size="small" style={{ marginRight: 10 }} />
              )}
              Uploaded {finishedCount}/{pendingUploads.length}
            </Typography.Text>
            {finishedCount === pendingUploads.length ? (
              <Button
                icon={<CloseOutlined />}
                type="text"
                size="small"
                onClick={() => dispatch(clearPendingUploads())}
              />
            ) : (
              <Button
                icon={minimized ? <UpOutlined /> : <DownOutlined />}
                type="text"
                size="small"
                onClick={() => setMinimized(!minimized)}
              />
            )}
          </Flex>
        }
      >
        <Row gutter={24}>
          <List
            height={pendingUploads.length ? 400 : 250}
            itemCount={pendingUploads.length}
            itemSize={100}
            width={"100%"}
            itemData={pendingUploads}
            overscanCount={10}
          >
            {({ index, style }) => (
              <Col span={24} key={index} style={style}>
                <UploadCard uploadItem={pendingUploads[index]} />
              </Col>
            )}
          </List>
        </Row>
      </Card>
    </div>
  );
};
