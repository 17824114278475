import React from "react";

const CustomPlatform = ({
  width = "100%",
  height = "auto",
}: {
  width?: string;
  height?: string;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 49 60"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.45459 45.4401L2.35081 44.795V58.0953H0.45459V45.4401Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.79859 44.1697V58.5433H0.0065918V45.1194L2.79859 44.1697ZM0.902373 45.7609V57.6475H1.90281V45.4206L0.902373 45.7609Z"
        fill="#6656ED"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.99951 0H39.5729L48.3615 8.78869V60H1.99951V0Z"
        fill="#6656ED"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.99951 0H39.5729L48.3615 8.78869V60H1.99951V0ZM39.4271 0.351062H2.35057V59.6477H48.0105V8.93446L39.4271 0.351062Z"
        fill="#5245BE"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.45459 45.4401H46.3915V58.0965H0.45459V45.4401Z"
        fill="#6656ED"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.0065918 44.9922H46.8392V58.5444H0.0065918V44.9922ZM0.902373 45.888V57.6486H45.9435V45.888H0.902373Z"
        fill="#5245BE"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M39.3604 0H39.5729L48.3616 8.7887V9.00249H39.3604V0Z"
        fill="#6656ED"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M39.3604 0H39.5729L48.3616 8.7887V9.00249H39.3604V0Z"
        fill="black"
        fill-opacity="0.2"
      />
      <path
        d="M6.19556 56.312V47.2233H10.0609C11.0327 47.2233 11.7397 47.3047 12.1794 47.4686C12.6216 47.6314 12.9726 47.9205 13.2374 48.3372C13.5023 48.7563 13.6347 49.2325 13.6347 49.7706C13.6347 50.4533 13.4367 51.0169 13.0358 51.4603C12.6361 51.9049 12.0397 52.1843 11.244 52.3009C11.6401 52.5317 11.9656 52.7856 12.2219 53.0626C12.4794 53.3395 12.8269 53.8327 13.263 54.5397L14.3708 56.312H12.177L10.8614 54.3283C10.3889 53.6238 10.0657 53.1792 9.8896 52.9945C9.71589 52.8123 9.53247 52.686 9.33811 52.618C9.14132 52.5499 8.83156 52.5171 8.40883 52.5171H8.0359V56.312H6.19556ZM8.0359 51.0692H9.3952C10.2747 51.0692 10.8225 51.0315 11.0424 50.9574C11.2611 50.8833 11.4348 50.7545 11.5575 50.5723C11.6826 50.3901 11.7433 50.1617 11.7433 49.8884C11.7433 49.5823 11.6607 49.3369 11.4979 49.1462C11.3352 48.9579 11.1044 48.8389 10.808 48.7903C10.6573 48.7696 10.2115 48.7587 9.4693 48.7587H8.0359V51.0692ZM17.4077 56.312V48.7587H14.7158V47.2233H21.9265V48.7587H19.248V56.312H17.4077ZM23.0927 56.312V47.2233H25.8502L27.4791 53.4221L29.0899 47.2233H31.8522V56.312H30.1516L30.1455 49.1571L28.3525 56.312H26.5839L24.8006 49.1571L24.7946 56.312H23.0927ZM33.6938 56.312V47.2233H36.6371C37.7523 47.2233 38.4775 47.267 38.8164 47.3581C39.3363 47.4954 39.7724 47.7918 40.1234 48.2485C40.4745 48.7065 40.6506 49.2956 40.6506 50.0196C40.6506 50.5784 40.5486 51.0461 40.3482 51.4275C40.1453 51.8077 39.889 52.1065 39.578 52.324C39.267 52.5402 38.9488 52.6836 38.6281 52.754C38.1896 52.8403 37.5567 52.8852 36.727 52.8852H35.5341V56.312H33.6938ZM35.5341 48.7587V51.3485H36.5351C37.2566 51.3485 37.7365 51.3 37.9806 51.2052C38.2236 51.1092 38.4143 50.961 38.5516 50.7557C38.6888 50.5529 38.7593 50.3184 38.7593 50.0476C38.7593 49.7147 38.6621 49.4426 38.469 49.2264C38.2746 49.0102 38.0304 48.8753 37.7328 48.8207C37.5142 48.7794 37.0769 48.7587 36.416 48.7587H35.5341Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.77808 10.5925H41.5848V35.1984H8.77808V10.5925Z"
        fill="#6656ED"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.77808 10.5925H41.5848V35.1984H8.77808V10.5925Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.77808 13.3914H41.5848V16.3869H8.77808V13.3914Z"
        fill="#5245BE"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.77808 10.567H41.5848V13.3913H8.77808V10.567Z"
        fill="#5245BE"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.4785 10.5743H14.2724C14.6636 11.5425 15.0535 12.5094 15.4447 13.4764C15.0535 14.4433 14.6636 15.4102 14.2724 16.3784H11.4785C11.8697 15.4102 12.2596 14.4433 12.6507 13.4764C12.2596 12.5094 11.8697 11.5425 11.4785 10.5743ZM17.3385 10.5743H20.1324C20.5223 11.5425 20.9135 12.5094 21.3046 13.4764C20.9135 14.4433 20.5223 15.4102 20.1324 16.3784H17.3385C17.7296 15.4102 18.1195 14.4433 18.5107 13.4764C18.1195 12.5094 17.7296 11.5425 17.3385 10.5743ZM23.1984 10.5743H25.9923C26.3822 11.5425 26.7734 12.5094 27.1633 13.4764C26.7734 14.4433 26.3822 15.4102 25.9923 16.3784H23.1984C23.5883 15.4102 23.9795 14.4433 24.3694 13.4764C23.9795 12.5094 23.5883 11.5425 23.1984 10.5743ZM29.0571 10.5743H31.851C32.2422 11.5425 32.6321 12.5094 33.0233 13.4764C32.6321 14.4433 32.2422 15.4102 31.851 16.3784H29.0571C29.4483 15.4102 29.8394 14.4433 30.2294 13.4764C29.8394 12.5094 29.4483 11.5425 29.0571 10.5743ZM34.9171 10.5743H37.711C38.1021 11.5425 38.4921 12.5094 38.8832 13.4764C38.4921 14.4433 38.1021 15.4102 37.711 16.3784H34.9171C35.3082 15.4102 35.6981 14.4433 36.0893 13.4764C35.6981 12.5094 35.3082 11.5425 34.9171 10.5743Z"
        fill="white"
      />
      <path
        d="M25.1808 33.3326C29.2867 33.3326 32.6151 30.0042 32.6151 25.8984C32.6151 21.7925 29.2867 18.4641 25.1808 18.4641C21.075 18.4641 17.7466 21.7925 17.7466 25.8984C17.7466 30.0042 21.075 33.3326 25.1808 33.3326Z"
        fill="#6656ED"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.9648 27.2443C27.4573 28.142 25.951 29.0397 24.4435 29.9374C21.7151 31.5616 22.1816 31.7839 22.1816 28.5915V23.2053C22.1816 20.013 21.7139 20.2353 24.4423 21.8594C25.951 22.7571 27.4573 23.6548 28.9648 24.5525C31.67 26.162 31.67 25.6348 28.9648 27.2443Z"
        fill="white"
      />
    </svg>
  );
};

export default CustomPlatform;
