import { axiosInstance as api } from '../helpers/request'
import { IAudio } from '../types/models';

class AudioService {

    savePresignedAudio({ originalName, Key, folderId }: { originalName: string, Key: string, folderId: string }) {
        return api.post<IAudio>(`/audio/presigned/save`, { originalName, Key, folderId })
    }

    getAudioList({ query = "", pageNumber, folderId }: { query?: string, pageNumber: number, folderId?: string }) {
        let url = `/audio?search=${query}&page=${pageNumber}&perPage=10000`
        if (folderId) {
            url += `&folderId=${folderId}`;
        }
        return api.get<{ data: IAudio[] }>(url)
    }

    getAudioById(id: string) {
        return api.get<IAudio>(`/audio/${id}`);
    }

    deleteAudio(id: string) {
        return api.delete<IAudio>(`/audio/${id}`);
    }

    duplicateAudio(id: string) {
        return api.post<IAudio>(`/audio/duplicate/${id}`);
    }

    updateAudio(data: Pick<IAudio, 'id'> | Partial<IAudio>) {
        return api.patch<IAudio>(`/audio/${data.id}`, data)
    }

}

const AudioServiceInstance = new AudioService();

export default AudioServiceInstance;