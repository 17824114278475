import axios, { AxiosError } from "axios";
import { ROUTES_MAP } from "../routes/routesMap";

const baseURL =
  process.env.REACT_APP_API_URL

export const getToken = () =>
  typeof window !== "undefined" && localStorage.getItem("token")
    ? localStorage.getItem("token")
    : null;

export const getAuthorizationHeader = () => `Bearer ${getToken()}`;

export const axiosInstance = axios.create({
  baseURL,
  headers: { Authorization: getAuthorizationHeader() },
});

axiosInstance.interceptors.request.use((config) => {
  const token = getAuthorizationHeader();
  if (token) {
    config.headers.Authorization = token;
  }
  config.headers['x-app-version'] = 3;
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error?.response?.status === 403) {
      let lastLocationKey = "location";
      try {
        const user = JSON.parse(window.localStorage.getItem("user") as string);
        if (user) {
          lastLocationKey = `location-${user.id}`;
        }
      } catch (e) { }
      window.localStorage.setItem(lastLocationKey, window.location.href);
      window.localStorage.removeItem("user");
      window.localStorage.removeItem("token");
      window.location.href = ROUTES_MAP.auth.login;
      return;
    }
    throw new Error((error.response?.data as any)?.error || (error.response?.data as any)?.message || "Something went wrong");
  }
);
