import { Button, Flex, Modal, Spin, Typography } from "antd";
import { FC, useState } from "react";
import OtpInput from "react-otp-input";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import {
  logoutUser,
  resendVerifyUser,
  verifyUser,
} from "../../../redux/Slices/AuthSlice";

const VerifyModal: FC = () => {
  const { user, isLoading, verifyUserLoading, resendLoading } = useAppSelector(
    (state) => state.auth
  );
  const [resendSubmitted, setResendSubmitted] = useState(false);
  const [otp, setOtp] = useState("");
  const dispatch = useAppDispatch();

  const onResend = () => {
    dispatch(resendVerifyUser());
    setResendSubmitted(true);
  };

  const onLogoutUser = () => {
    dispatch(logoutUser());
  };

  return (
    <Modal
      open={user && !user.verified}
      // open={true}
      title="Verify your email"
      centered
      okButtonProps={{ disabled: otp.length < 4, loading: isLoading }}
      footer={[]}
      closeIcon={false}
    >
      <Typography.Paragraph
        style={{
          fontSize: "14px",
        }}
      >
        We've send a verification code to <strong>{user?.email}</strong> (if you
        can't find the email in your inbox, please check your spam folder)
      </Typography.Paragraph>
      <Typography.Paragraph
        style={{
          color: "rgba(255, 255, 255, 0.85)",
          marginTop: "20px",
        }}
      >
        verification code
      </Typography.Paragraph>
      <Flex justify="center" style={{ marginBottom: 35 }}>
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={4}
          renderInput={(props) => <input {...props} />}
          inputStyle={{
            margin: "0 5px",
            width: "100%",
            height: "60px",
            borderRadius: "8px",
          }}
          placeholder="_"
        />
      </Flex>
      <Button
        block
        size="large"
        type="primary"
        loading={verifyUserLoading}
        onClick={() => dispatch(verifyUser({ code: otp }))}
      >
        Submit
      </Button>
      <Flex vertical align="center" justify="center">
        {!resendSubmitted && (
          <Typography.Text
            style={{
              fontSize: "14px",
              marginTop: "25px",
              color: "white",
            }}
          >
            Didn't receive an email?
            <Button
              type="link"
              style={{
                fontWeight: "bold",
              }}
              size="small"
              onClick={onResend}
            >
              Resend
            </Button>
          </Typography.Text>
        )}
        {resendLoading && (
          <Spin
            style={{
              margin: "10px 0px",
            }}
          />
        )}
        <Typography.Text
          style={{
            fontSize: "14px",
            color: "white",

            marginTop: 20,
          }}
        >
          Or
          <Button
            onClick={onLogoutUser}
            style={{
              fontWeight: "bold",
              padding: 0,
              marginLeft: 5
            }}
            type="link"
          >
            Logout
          </Button>
        </Typography.Text>
      </Flex>
    </Modal>
  );
};

export default VerifyModal;
