import { Button, Card, Flex, Progress, Spin, Typography, theme } from "antd";
import { IPendingUploadItem } from "../../../types/interfaces";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { CloseOutlined } from "@ant-design/icons";
import { removePendingUploadItem } from "../../../redux/Slices/MediaSlice";

const UploadCard = ({ uploadItem }: { uploadItem: IPendingUploadItem }) => {
  const dispatch = useAppDispatch();
  const { currentItemUploadingId } = useAppSelector((state) => state.media);

  return (
    <Card size="small" className="gradient-card">
      <Flex vertical>
        <Flex
          style={{
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography.Paragraph
            ellipsis
            style={{ marginBottom: 0, width: "95%" }}
          >
            {uploadItem.body.name}
          </Typography.Paragraph>
          {uploadItem.id !== currentItemUploadingId && (
            <Button
              size="small"
              type="text"
              icon={<CloseOutlined />}
              onClick={() => {
                dispatch(removePendingUploadItem(uploadItem.id));
              }}
            />
          )}
        </Flex>

        <Flex
          align="center"
          justify="center"
          style={{
            width: "100%",
          }}
        >
          <Progress
            style={{
              marginBottom: 0,
              marginRight: 0,
              opacity: uploadItem.progress === 0 ? 0.5 : 1,
            }}
            percent={uploadItem.progress}
            showInfo={uploadItem.progress > 0}
          />
        </Flex>
      </Flex>
    </Card>
  );
};

export default UploadCard;
