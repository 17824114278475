import React from "react";

const YoutubeBw = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="52"
      height="37"
      viewBox="0 0 52 37"
      fill="none"
    >
      <path
        d="M20.5714 25.9614L33.9171 18.2471L20.5714 10.5328V25.9614ZM50.2971 5.82707C50.6314 7.03564 50.8629 8.65564 51.0171 10.7128C51.1971 12.7699 51.2743 14.5442 51.2743 16.0871L51.4286 18.2471C51.4286 23.8785 51.0171 28.0185 50.2971 30.6671C49.6543 32.9814 48.1629 34.4728 45.8486 35.1156C44.64 35.4499 42.4286 35.6814 39.0343 35.8356C35.6914 36.0156 32.6314 36.0928 29.8029 36.0928L25.7143 36.2471C14.94 36.2471 8.22857 35.8356 5.58 35.1156C3.26571 34.4728 1.77429 32.9814 1.13143 30.6671C0.797143 29.4585 0.565714 27.8385 0.411429 25.7814C0.231429 23.7242 0.154286 21.9499 0.154286 20.4071L0 18.2471C0 12.6156 0.411429 8.47564 1.13143 5.82707C1.77429 3.51278 3.26571 2.02136 5.58 1.3785C6.78857 1.04421 9 0.812784 12.3943 0.658498C15.7371 0.478498 18.7971 0.401356 21.6257 0.401356L25.7143 0.24707C36.4886 0.24707 43.2 0.658499 45.8486 1.3785C48.1629 2.02136 49.6543 3.51278 50.2971 5.82707Z"
        fill="#6656ED"
      />
    </svg>
  );
};

export default YoutubeBw;
