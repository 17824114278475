import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { REFERRAL_KEY } from "../../../constants/constants";

export const Join = () => {
  const params = useParams<{ id: string }>();

  useEffect(() => {
    if (params.id) {
      console.log("Got id", params.id);
      window.localStorage.setItem(REFERRAL_KEY, params.id);
      window.location.href = `https://www.streamingbots.com?uref=${params.id}`;
    }
  }, [params]);
  return <div>Loading</div>;
};
