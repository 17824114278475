import { axiosInstance as api } from '../helpers/request'
import { FolderTypeEnum, IFolder } from '../types/models';

class FolderService {
    getFolders(type: FolderTypeEnum) {
        return api.get<IFolder[]>(`/folder?type=${type}`)
    }

    getFolderById(id: string) {
        return api.get<IFolder>(`/folder/${id}`)
    }

    deleteFolder(id: string) {
        return api.delete<IFolder>(`/folder/${id}`);
    }

    createFolder(data: Pick<IFolder, 'name' | 'type'>) {
        return api.post<IFolder>(`/folder`, data)
    }

    updateFolder(data: Pick<IFolder, 'id' | 'name'>) {
        return api.patch(`/folder/${data.id}`, data)
    }

    addItemsToFolder(data: { folderId: string | null, itemIds: string[], type: FolderTypeEnum }) {
        return api.post(`/folder/items`, data)
    }
}

const FolderServiceInstance = new FolderService();

export default FolderServiceInstance;